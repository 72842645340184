import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import it from 'vuetify/lib/locale/it'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { it },
      current: 'it',
    },
    theme: {
      dark: false,
      themes: {
        light: {
          primary: "#1976D2",
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
          vecos:"#639694"
        }
      }
    },
  });
