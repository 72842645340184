<template>
  <v-flex d-flex>
    <v-dialog
      ref="dialogData"
      v-model="dialogData"
      :return-value.sync="data"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :disabled="disabled"
          v-model="formattedData"
          :label="label"
          readonly
          v-on="on"
          :outlined="outlined"
          :locale="locale"
          :clearable="clearable"
          :dense="dense"
          :hide-details="`hide-details`"
          @click:clear="clearData"
          @blur="data = parseDate(formattedData)"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="data"
        scrollable
        :allowed-dates="allowedDates"
        :events="events"
        :event-color="eventColor"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialogData = false">Chiudi</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            $refs.dialogData.save(data);
            returnData();
          "
          >OK</v-btn
        >
      </v-date-picker>
    </v-dialog>
  </v-flex>
</template>

<script>
export default {
  props: {
    value: { type: String },
    disabled: { type: Boolean },
    label: { type: String },
    allowedDates: {},
    outlined: { type: Boolean, default: false },
    locale: { type: String, default: "it-IT" },
    events: {},
    eventColor: { type: String, default: "primary" },
    clearable: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    'hide-details': { type: Boolean, default: false },
  },
  data() {
    return {
      dialogData: false,
      data: "",
      formattedData: "",
    };
  },
  methods: {
    returnData() {
      this.$emit("input", this.data);
      this.$emit("change", this.data);
    },
    clearData() {
      this.$emit("input", "");
      this.$emit("change", "");
    },
    dateToItalianFormat(originDate) {
      if (!originDate) return null;
      let date = new Date(originDate);
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      return day + "/" + month + "/" + year;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  mounted() {
    this.data = this.value;
  },
  watch: {
    value: function () {
      this.data = this.value;
    },
    data: function (val) {
      this.formattedData = this.dateToItalianFormat(val);
    },
  },
};
</script>