<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card justify-center class="rounded-card">
        <v-card-title class="headline">{{dialogTitle}}</v-card-title>
        <v-card-text>
          <v-flex>
            <v-icon x-large>mdi-information</v-icon>
            {{dialogMessage}}
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="chiudiMaschera()" text>CHIUDI</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
/* eslint-disable */
import { serverBus } from "../main";

export default {
  data() {
    return {
      serverBusMessage: "messaggio",
      salvataggio: false
    };
  },
  props: {
    value: { type: Boolean },
    name: "",
    dialogTitle: "",
    dialogMessage: ""
  },
  methods: {
    chiudiMaschera() {
      this.$store.commit("setInfoMessage", null);
      this.$emit("input", false);
      //this.dialogMessage = ""
      //this.$emit('update:dialogError', false)
      //this.close() //.hide('dialog')
    }
  },
  created() {
    var vm = this;
    serverBus.$on("serverSelected", message => {
      this.serverBusMessage = message;
      if (message == "Chiudi") {
        this.$emit("update:dialog", false);
      }
    });
  },
  computed: {
    dialog: function() {
      return this.value;
    }
  }
};
</script>