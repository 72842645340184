export default [
"#49B189",
"#FD1F43",
"#9F5684",
"#87A022",
"#E35CF4",
"#B76CE2",
"#0D48CC",
"#152D29",
"#E7177E",
"#646E9A",
"#50E7D5",
"#2BD5E2",
"#ED5A60",
"#568F16",
"#E2DAC3",
"#B40E4F",
"#9620A7",
"#B0CB4B",
"#417A25",
"#A5D9F7",
"#CE7557",
"#6A2235",
"#C03F6A",
"#65B1BB",
"#A366C6",
"#96C46E",
"#FC6E25",
"#D7E72D",
"#DA00D4",
"#C6D9A3",
"#0ED16C",
"#9F7D83",
"#E261A3",
"#3B3672",
"#03CB10",
"#BC8076",
"#4AEE3A",
"#FB9425",
"#CDD0C0",
"#F65901",
"#749813",
"#900E43",
"#45F31D",
"#8E86DF",
"#6F77AA",
"#4E5CE9",
"#72BE7C",
"#8D5EFA",
"#41BE16",
"#45F31D",
"#8E86DF",
"#6F77AA",
"#4E5CE9",
"#72BE7C",
"#8D5EFA",
"#76636A",
"#41BE16"]