<template>
  <span>
    <v-text-field
      :label="label"
      :outlined="outlined"
      :dense="dense"
      append-icon="mdi-calendar"
      v-model="date"
      @blur="date = dateToItalianFormat(value)"
      :rules="[rules.dateValid]"
      @click:append="modal = true"
      :hide-details="`hide-details`"
    ></v-text-field>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <v-date-picker v-model="isoDate" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(date)">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: {
    value: { type: String },
    disabled: { type: Boolean },
    label: { type: String },
    allowedDates: {},
    outlined: { type: Boolean, default: false },
    locale: { type: String, default: "it-IT" },
    events: {},
    eventColor: { type: String, default: "primary" },
    clearable: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    "hide-details": { type: Boolean, default: false },
  },
  data() {
    return {
      date: "",
      rules: {
        dateValid: (value) => !!value && !!this.convertToIso(value),
      },
      modal: false,
    };
  },
  methods: {
    shortDateToISO(date) {
      let day = date.substring(0, 2);
      let month = date.substring(2, 4);
      let year = date.substring(4, 6);

      let d = new Date();
      d.setDate(day);
      d.setMonth(month - 1);
      d.setFullYear(20 + year);
      return d.toISOString().substring(0, 10);
    },
    longDateToISO(date) {
      let day = date.substring(0, 2);
      let month = date.substring(2, 4);
      let year = date.substring(4, 8);

      let d = new Date();
      d.setDate(day);
      d.setMonth(month - 1);
      d.setFullYear(year);
      return d.toISOString().substring(0, 10);
    },
    itDateToISO(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    dateToItalianFormat(originDate) {
      if (!originDate) return null;
      let date = new Date(originDate);
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      return day + "/" + month + "/" + year;
    },
    convertToIso(val) {
      if (val.length < 6) return;

      let shortDateExp = new RegExp("^([0-9]{4}|[0-9]{6})$");
      let longDateExp = new RegExp("[0-9]{8}$");
      let itDateExp = /\d\d\/\d\d\/\d\d\d\d/i;
      let isoDateExp = /\d\d\d\d-\d\d-\d\d/i;
      let date = null;

      if (val.length == 6 && shortDateExp.test(val)) {
        date = this.shortDateToISO(val);
      } else if (val.length == 8 && longDateExp.test(val)) {
        date = this.longDateToISO(val);
      } else if (val.length == 10 && itDateExp.test(val)) {
        date = this.itDateToISO(val);
      } else if (val.length == 10 && isoDateExp.test(val)) {
        date = val;
      }

      return date;
    },
  },
  mounted() {
    this.date = this.value;
  },
  watch: {
    value: function () {
      this.date = this.value;
    },
    date: function (val) {
      if (val.length < 6) return;

      let date = this.convertToIso(val);

      if (date) {
        this.date = this.dateToItalianFormat(date);
        this.$emit("input", date);
      }
    },
  },
  computed: {
    isoDate: {
      get() {
        return this.convertToIso(this.date);
      },
      set(value) {
        let date = this.convertToIso(value);

        if (date) {
          this.date = this.dateToItalianFormat(date);
        }
      },
    },
  },
};
</script>

<style>
</style>