<template>
  <v-app>
    <v-app-bar dark app color="vecos">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.mdAndDown && loginEffettuato()"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <b>BENVENUTO {{ username }}</b>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="logout" v-if="loginEffettuato()">
        {{ !$vuetify.breakpoint.smAndDown ? "Logout" : "" }}
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <DialogOperationRunning
          name="dialogOperationRunning"
          v-model="progressMessageEnable"
          :dialogTitle="operationRunningTitle"
          :dialogMessage="progressMessage"
          :loading="progressLoading"
        />
        <DialogError
          v-model="errorMessageEnable"
          name="dialogError"
          :dialogTitle="errorDialogTitle"
          :dialogMessage="errorMessage"
        />
        <DialogInfo
          v-model="infoMessageEnable"
          name="dialogError"
          dialogTitle="Info"
          :dialogMessage="infoMessage"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MixinCommonComp from "./mixin/MixingCommonComp";

export default {
  mixins: [MixinCommonComp],
  name: "App",
  data: () => ({
    drawer: false,
    image: require("@/assets/logo.svg"),
  }),
  methods: {
    logout() {
      this.$session.clear();
      this.$session.destroy();
      this.$router.push({ path: "/Login/" });
    },
    home() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
