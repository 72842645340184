/* eslint-disable */
import {
  Conf
} from '../conf/defaults'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    //Error Message
    errorMessage: null,
    //Work in progress Dialog
    dialogMessage: null,
    //Messages not in a dialog but inside the window
    progressMessage: null,
  },
  mutations: {
    setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage
    },
    setDialogMessage(state, dialogMessage) {
      state.dialogMessage = dialogMessage
    },
    setProgressMessage(state, progressMessage) {
      state.progressMessage = progressMessage
    },
  },
  actions: {
    richiestaLogin({
      state,
      commit,
      rootState,
      dispatch
    }, params) {
      var self = this
      console.log("richiestaLogin")
      return new Promise((resolve, reject) => {

        commit('setProgressMessage', "Login in corso");
        commit('setDialogMessage', "Login in corso");
        console.log("richiestaLogin utente" + params.utente)
        console.log("richiestaLogin password" + params.password)
        var url = Conf.idempiereUrl +
          "webservices/rest/v1/auth/authUserPwd"

        //?codiceDitta=" +
        //Conf.codiceDitta +
        //"&password=" + params.password + "&utente=" + params.utente

        var risp = {}

        /*
        axios.get(url).then(response => {
            console.log("risposta ricevuta")
            if (response.data) {
                risp = response.data
                resolve(response.data);
            }
        })
        .catch(error => {
            console.log(error)
        })
        */
        var parameters = {};
        parameters.userName = params.utente
        parameters.password = params.password

        axios.defaults.headers["Content-Type"] =
          "application/json;charset=utf-8";
        axios
          .post(
            url,
            //parameters,
            JSON.stringify(parameters), // the data to post
            {
              headers: {
                "Content-type": "application/json;charset=utf-8",
                "Accept": 'application/json'
              }
            }
          )
          .then(response => {
            console.log(response.data);
            resolve(response.data);
          })
          .catch(e => {
            console.log("Eccezione");
            console.log(e);
            //this.errors.push(e)
          });

        commit('setProgressMessage', "Ricerca completata");
        commit('setDialogMessage', null);

        //resolve(risp);
      }, error => {
        // http failed, let the calling function know that action did not work out
        console.log("Errore")
        console.log(error)
        state.commit('setErrorMessage', error);
        reject(error);
      })
    }
  },
  getters: {
    errorMessage: state => state.errorMessage,
    progressMessage: state => state.progressMessage,
    dialogMessage: state => state.dialogMessage
  }
}
