<template>
  <v-container>
    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card v-if="event">
        <v-card-title>{{ event.name }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <DatePickerText
                outlined
                dense
                label="Data"
                v-model="date"
              ></DatePickerText>
              <!--<DatePicker v-model="date" outlined dense label="Data" hide-details />-->
            </v-col>
            <v-col>
              <TimePickerText
                outlined
                dense
                label="Ora inizio"
                v-model="startdate_hhmm"
                format="24hr"
                :allowedMinutes="allowedMinutes"
                hide-details
                @change="isAssetAvailable"
              ></TimePickerText>
              <!--<TimePicker
                outlined
                label="Ora Inizio"
                v-model="startdate_hhmm"
                format="24hr"
                :allowedMinutes="allowedMinutes"
                dense
                hide-details
                @change="isAssetAvailable"
              ></TimePicker>-->
            </v-col>
            <v-col>
            <TimePickerText
                outlined
                dense
                label="Ora Fine"
                v-model="enddate_hhmm"
                format="24hr"
                :allowed-hours="allowedHours"
                :allowedMinutes="allowedEndMinutes"
                hide-details
                @change="isAssetAvailable"
              ></TimePickerText>
              <!--<TimePicker
                outlined
                label="Ora Fine"
                v-model="enddate_hhmm"
                format="24hr"
                :allowed-hours="allowedHours"
                :allowedMinutes="allowedEndMinutes"
                dense
                hide-details
              ></TimePicker>-->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Cliente"
                hide-no-data
                hide-selected
                :items="listaClienti"
                item-text="name"
                item-value="c_bpartner_id"
                v-model="c_bpartner_id"
                :search-input.sync="ricercaCliente"
                outlined
                dense
                @input="getOpenProjects"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                label="Personale"
                dense
                outlined
                :items="users"
                item-text="name"
                item-value="ad_user_id"
                v-model="selectedUsers"
                multiple
                chips
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                label="Strumento"
                dense
                outlined
                :items="assets"
                item-text="name"
                item-value="a_asset_id"
                v-model="a_asset_id"
                clearable
                @change="isAssetAvailable"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <br />
          <v-text-field
            label="Filtra Ordini"
            dense
            outlined
            v-model="filter"
          ></v-text-field>
          <v-data-table
            dense
            :headers="headers"
            :items="projects"
            item-key="fct_project_id"
            show-select
            v-model="selectedProjects"
            :search="filter"
            :loading="tableLoading"
            :ripple="true"
          >
            <template
              v-slot:item.data-table-select="{ item, select, isSelected }"
            >
              <v-checkbox
                :input-value="isSelected"
                @change="select(!isSelected)"
                hide-details
                class="no-margin-checkbox"
                color="dark-grey"
                :disabled="item.completed"
              ></v-checkbox>
            </template>
            <template v-slot:item.programmato="{ item }">
              <v-icon color="success" v-if="item.programmato">mdi-check</v-icon>
              <v-icon color="error" v-else>mdi-close</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteEvent">
            <v-icon>mdi-delete</v-icon> Elimina
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="save" :disabled="saveDisabled"
            >Salva</v-btn
          >
          <v-btn text color="primary" @click="close">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-container>
</template>

<script>
import mixin from "../mixin/MixingCommonComp";
import ConfirmDialog from "./ConfirmDialog.vue";
import DatePicker from "./DataPicker.vue";
import TimePicker from "./TimePicker";

import DatePickerText from "./DatePickerText.vue";
import TimePickerText from "./TimePickerText.vue";

/*eslint-disable */
export default {
  mixins: [mixin],
  components: {
    TimePicker,
    DatePicker,
    ConfirmDialog,
    DatePickerText,
    TimePickerText,
  },
  data() {
    return {
      event: null,
      users: null,
      resolve: null,
      reject: null,
      dialog: false,
      date: new Date().toISOString().substring(0, 10),
      ricercaCliente: "",
      filter: "",
      c_bpartner_id: 0,
      selectedUsers: [],
      listaClienti: [],
      projects: [],
      selectedProjects: [],
      assets: [],
      a_asset_id: 0,
      startdate_hhmm: "00:00",
      enddate_hhmm: "00:00",
      headers: [
        { text: "Ordine", value: "documentno" },
        { text: "Oggetto", value: "oggetto" },
        { text: "Matricola Inail", value: "matricolaInail" },
        { text: "Già Programmato", value: "programmato" },
      ],
      tableLoading: false,
    };
  },
  methods: {
    allowedMinutes: (m) => m % 15 === 0,
    allowedHours(h) {
      let startHour = parseInt(this.startdate_hhmm.split(":")[0]);
      let startMinute = parseInt(this.startdate_hhmm.split(":")[1]);
      if (startMinute >= 45) {
        return h > startHour;
      }
      return h >= startHour;
    },
    allowedEndMinutes(m) {
      let startHour = parseInt(this.startdate_hhmm.split(":")[0]);
      let endHour = parseInt(this.enddate_hhmm.split(":")[0]);
      let startMinute = parseInt(this.startdate_hhmm.split(":")[1]);
      if (endHour > startHour) {
        return m % 15 === 0;
      }
      return m > startMinute && m % 15 === 0;
    },
    async open(event) {
      this.showLoadingDialog(true, "Apertura evento...");
      this.event = event;
      this.selectedUsers.push(...event.users);
      this.date = this.toISOStringWithTimezone(
        new Date(this.event.start)
      ).substring(0, 10);
      this.ricercaCliente = event.bpname;
      this.c_bpartner_id = event.c_bpartner_id;
      this.a_asset_id = event.a_asset_id;
      await this.getOpenProjects();
      await this.getScheduledProjects();
      this.startdate_hhmm = new Date(this.event.start)
        .toLocaleTimeString()
        .substring(0, 5);
      this.enddate_hhmm = new Date(this.event.end)
        .toLocaleTimeString()
        .substring(0, 5);
      this.dialog = true;
      this.showLoadingDialog(false);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.dialog = false;
      this.selectedUsers = [];
      this.filter = "";
      this.resolve(this.event);
    },
    save() {
      let dateStart = new Date(this.date + " " + this.startdate_hhmm);
      let dateStop = new Date(this.date + " " + this.enddate_hhmm);
      let users = this.selectedUsers.map((x) => ({ ad_user_id: x }));
      let projects = this.selectedProjects.map((x) => ({
        fct_project_id: x.fct_project_id,
      }));
      let parameters = {};
      parameters.data = {
        fc_scheduledjob_id: this.event.fc_scheduledjob_id,
        c_bpartner_id: this.c_bpartner_id,
        a_asset_id: this.a_asset_id,
        dateStart: dateStart,
        dateStop: dateStop,
        users: users,
        projects: projects,
      };
      parameters.idempiereRestPath = "vecos/insertUpdateScheduledJob";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        this.dialog = false;
        this.selectedUsers = [];
        this.filter = "";
        this.resolve(this.event);
      });
    },
    async deleteEvent() {
      if (
        !(await this.$refs.confirm.open(
          "Elimina",
          "Vuoi eliminare questo evento ?"
        ))
      )
        return;
      let parameters = {};
      parameters.data = {
        fc_scheduledjob_id: this.event.fc_scheduledjob_id,
      };
      parameters.idempiereRestPath = "vecos/deleteScheduledJob";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.dialog = false;
          this.selectedUsers = [];
          this.filter = "";
          this.resolve(this.event);
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    async getOpenProjects() {
      this.projects = [];
      this.selectedProjects = [];
      this.tableLoading = true;
      let parameters = {};
      parameters.data = {
        c_bpartner_id: this.c_bpartner_id,
      };
      parameters.idempiereRestPath = "vecos/getOpenProjects";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.projects = response.data.projects;
          this.tableLoading = false;
        });
    },
    async getScheduledProjects() {
      let parameters = {};
      parameters.data = {
        fc_scheduledjob_id: this.event.fc_scheduledjob_id,
      };
      parameters.idempiereRestPath = "vecos/getSheduledJobProjects";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.selectedProjects = response.data.projects;
          for (let project of this.selectedProjects) {
            let prj = this.projects.find((item) => {
              return item.fct_project_id == project.fct_project_id;
            });
            if (!prj) {
              this.projects.push(project);
            }
            this.projects.sort((a, b) => {
              let selA = this.selectedProjects.find((item) => {
                return item.fct_project_id == a.fct_project_id;
              });
              let selB = this.selectedProjects.find((item) => {
                return item.fct_project_id == b.fct_project_id;
              });
              if (selA) return -1;
              if (selB) return 1;
              return 0;
            });
          }
        });
    },
    getSalesReps() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getSalesReps";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.users = response.data.salesReps;
      });
    },
    getAssets() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "vecos/getAssets";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.assets = response.data.assets;
      });
    },
    toISOStringWithTimezone(date) {
      const tzOffset = -date.getTimezoneOffset();
      const diff = tzOffset >= 0 ? "+" : "-";
      const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, "0");
      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        diff +
        pad(tzOffset / 60) +
        ":" +
        pad(tzOffset % 60)
      );
    },
    isAssetAvailable() {
      let dateStart = new Date(this.date + " " + this.startdate_hhmm);
      let dateStop = new Date(this.date + " " + this.enddate_hhmm);
      let parameters = {};
      parameters.data = {
        fc_scheduledjob_id: this.event.fc_scheduledjob_id,
        a_asset_id: this.a_asset_id,
        dateStart: dateStart,
        dateStop: dateStop,
      };
      parameters.idempiereRestPath = "vecos/isAssetAvailable";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "KO") {
          this.showErrorDialog(
            "Strumento già utilizzato per " + response.data.bpname
          );
          this.a_asset_id = 0;
        }
      });
    },
  },
  mounted() {
    this.getSalesReps();
    this.getAssets();
  },
  computed: {
    saveDisabled() {
      if (!this.c_bpartner_id) return true;
      if (!this.selectedUsers) return true;
      if (this.selectedUsers.length <= 0) return true;
      /*if (!this.selectedProjects) return true;
      if (this.selectedProjects.length <= 0) return true;*/
      if (this.startdate_hhmm == this.enddate_hhmm) return true;
      return false;
    },
  },
  watch: {
    ricercaCliente: function () {
      if (
        this.ricercaCliente == null ||
        this.ricercaCliente == "" ||
        this.ricercaCliente.length < 3
      )
        return;
      let parameters = {};
      parameters.data = { name: this.ricercaCliente };
      parameters.idempiereRestPath = "effecitech/cercaCliente";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.listaClienti = response.data.listaClienti;
        }
      });
    },
    startdate_hhmm() {
      this.isAssetAvailable();
    },
    enddate_hhmm() {
      this.isAssetAvailable();
    },
    date() {
      this.isAssetAvailable();
    },
  },
};
</script>

<style>
.no-margin-checkbox {
  margin: 0 !important;
}
</style>