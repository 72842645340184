// define a mixin object
/*eslint-disable*/
import DialogOperationRunning from '@/components/DialogOperationRunning'
import DialogError from '@/components/DialogError'
import BreadCrumbs from '@/components/commons/BreadCrumbs'
import LoginMenu from "@/components/selectUser"
import DialogInfo from "@/components/DialogInfo"
import { Conf } from "../conf/defaults.js"

export default {
  components: {
    DialogOperationRunning,
    DialogError,
    DialogInfo,
    BreadCrumbs,
    LoginMenu,
  },
  data() {
    return {
      operationRunningTitle: 'INFO',
      dialogStampeModel: false,
      dialogSmsModel: false,
      dialogNotificheModel: false,
      errorDialogTitle: ""
    }
  },
  mounted: function () {
    if (this.$router.currentRoute.name == "login") return
    if (this.$router.currentRoute.name == "Signup") return

    let ad_session_uu = this.$route.query.ad_session_uu;

    if (ad_session_uu != null && ad_session_uu != "") {
      this.$session.set("uuid", ad_session_uu);
      this.$store.commit("setUUID", ad_session_uu);

      let username = this.$route.query.username;
      if(username){
        this.$store.commit("setUsername",username)
        this.$session.set("name", username);
      }
    }

    if (this.$session.get('uuid') == null) {
      this.$router.push({ path: '/Login/' })
    }
    this.$store.commit("setProgressMessage", null);
    this.$store.commit("setErrorMessage", null);

    let parameters = {}
    parameters.data = { json: JSON.stringify({ ad_session_uu: this.$session.get('uuid') }) };
    parameters.idempiereRestPath = "auth/checkActiveSession";

    this.$store.dispatch("callIdempiereRest", parameters).then(
      response => {
        if (response.data.result != "OK") {
          this.$session.clear();
          this.$session.destroy();
          this.$router.push({ path: "/Login/" });
        }
      }
    );
    document.addEventListener("mouseup", e => {
      let parameters = {}
      this.$store.dispatch("checkSession", parameters).then(response=>{
        if(response.data.result!="OK"){
          if(this.$router.currentRoute.name != 'login' && this.$router.currentRoute.name!="Signup")
            this.$router.push({path:'/login'})
        }
      });
    })
  },
  methods: {
    loginEffettuato: function () {
      if (this.$session.get("uuid") == null) return false;
      else return true;
    },
    isClienteSelezionato() {
      if (this.$store.getters.cliente != null)
        return true
      return false
    },
    showErrorDialog(message) {
      this.$store.commit("setProgressMessage", null);
      this.$store.commit("setInfoMessage", null);
      this.$store.commit("setErrorMessage", message);
    },
    showLoadingDialog(show, message = "") {
      if (show) {
        this.$store.commit("setErrorMessage", null);
        this.$store.commit("setInfoMessage", null);
        this.$store.commit("setProgressMessage", message);
      } else {
        this.$store.commit("setProgressMessage", null);
      }
    },
    showSuccessDialog(message, time = 3000) {
      this.$store.commit("setErrorMessage", null);
      this.$store.commit("setInfoMessage", null);
      this.$store.commit("setProgressLoading", false);
      this.$store.commit("setProgressMessage", message);
      var that = this
      setTimeout(function () {
        that.$store.commit("setProgressMessage", null);
        that.$store.commit("setProgressLoading", true);
      }, time)
    },
    showInfoDialog(message) {
      this.$store.commit("setErrorMessage", null);
      this.$store.commit("setProgressLoading", true);
      this.$store.commit("setProgressMessage", null);
      this.$store.commit("setInfoMessage", message);
    },
    dateToItalianFormat(originDate) {
      if(originDate==null || originDate=="") return "";
      let date = new Date(originDate);
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let day = date.getDate().toString().padStart(2, "0");
      return day + "/" + month + "/" + year;
    }
  },
  computed: {
    progressMessage: function () {
      // console.log('progressMessage')
      return this.$store.state.progressMessage
    },
    progressMessageEnable: function () {
      // console.log('progressMessageEnable')
      if (this.$store.state.progressMessage == null) {
        return false
      } else {
        return true
      }
    },
    progressLoading: function () {
      return this.$store.state.progressLoading;
    },
    errorMessage: function () {
      return this.$store.state.errorMessage
    },
    errorMessageEnable: function () {
      if (this.$store.state.errorMessage == null) {
        console.log("errorMessageEnable --> false");
        return false;
      } else {
        console.log("errorMessageEnable --> true");
        return true;
      }
    },
    infoMessage: function () {
      return this.$store.state.infoMessage
    },
    infoMessageEnable: function () {
      if (this.$store.state.infoMessage == null) {
        console.log("infoMessageEnable --> true");
        return false;
      } else {
        console.log("infoMessageEnable --> false");
        return true;
      }
    },
    breadcrumbsitems: function () {
      return this.$store.state.breadcrumbsitems
    },
    buildno: function () {
      return Conf.buildNo.replaceAll("-", "").replaceAll("_", "");
    },
    username(){
      return this.$store.state.username
    }
  }
}
