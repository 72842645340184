<template>
  <v-menu offset-y v-if="type == 'menu'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">Cambio utente</v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(user, index) in userLogin"
        :key="index"
        @click="login(user)"
      >
        <v-list-item-title>{{ user.username }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-select
    label="Operatore"
    v-else-if="type == 'select'"
    :items="userLogin"
    item-text="username"
    return-object
    v-model="user"
    outlined
  ></v-select>
</template>

<script>
import { Conf } from "../conf/defaults";
export default {
  props: {
    type: {
      type: String,
      default: "menu",
    },
  },
  data() {
    return {
      userLogin: [],
    };
  },
  mounted() {
    this.userLogin = Conf.userLogin;
  },
  methods: {
    login(user) {
      var parameters = {};
      parameters.utente = user.username;
      parameters.password = user.password;

      this.$store.commit("setProgressMessage", "Accesso in corso");

      //this.$store.commit("setUUID", this.$session.get("uuid"))
      this.$store.dispatch("login/richiestaLogin", parameters).then(
        (response) => {
          console.log(response);
          if (response.result == "KO" || response.result == "Wrong username") {
            this.$store.commit("setProgressMessage", null);
          } else {
            this.$session.start();
            //this.$session.set("ad_client_id",response.data.ad_client_id)
            this.$session.set("uuid", response.session_uu);
            //this.$store.commit("setNavBarPermanent", true);
            this.$store.commit("setUUID", response.session_uu);
            this.$session.set("ad_client_id", response.ad_client_id);
            this.$session.set("ad_user_id", response.ad_user_id);
            this.$session.set("ad_org_id", response.ad_org_id);
            this.$session.set("ad_user_id", response.ad_user_id);
            this.$session.set("name", response.name);
            //this.$store.commit("setNavBarPermanent", true);
            this.$store.commit("setProgressMessage", null);
            this.$store.commit("setDatiRicerca", null);
          }
        },
        (error) => {
          this.$store.commit("setErrorMessage", "Errore di login " + error);
          //console.error("Got nothing from server. Prompt user to check internet connection and try again")
        }
      );
    },
  },
  computed: {
    user: {
      get() {
        return this.userLogin.find((item) => {
          return (item.username == this.$session.get("name"));
        });
      },
      set(user){
        this.login(user)
      }
    },
  },
};
</script>

<style>
</style>