<template>

    <v-flex d-flex xs9>    
      <v-breadcrumbs divider="/">
        <v-breadcrumbs-item
            v-for="item in breadcrumbItems"
            :key="item.text"
            :disabled="item.disabled"
            :to="item.link"
        >
            {{ item.text }}
        </v-breadcrumbs-item>

      </v-breadcrumbs>
    </v-flex>

</template>
<script>
// import { store } from '@/store/store'

/* eslint-disable */
export default {
  data () {
    return {
    }
  },
  created() {
    // if (this.$session.get('uuid')) {
    //  this.ricaricaDati()
    // }
  },
  computed: {
    breadcrumbItems: function () {
      //console.log("[m]getCurrentState") 
      // return [] 
      // console.log("******* computed >>>>   computed breadcrumbItems " + this.$store.state.breadcrumbItems)
      return this.$store.state.breadcrumbItems
    },
  }
}
</script>