<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card justify-center class="rounded-card">
        <v-card-title class="headline">{{dialogTitle}}</v-card-title>
        <v-card-text>
          <v-flex>
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
            <v-icon color="success" v-else>mdi-check</v-icon>
            {{dialogMessage}}
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
/* eslint-disable */
import { serverBus } from "../main";

export default {
  data() {
    return {
      serverBusMessage: "messaggio",
      salvataggio: false
    };
  },
  props: {
    value: { type: Boolean },
    name:"",
    dialogTitle: "",
    dialogMessage: "",
    loading:{
      default:true
    }
  },
  methods: {},
  created() {
    var vm = this;
    serverBus.$on("serverSelected", message => {
      this.serverBusMessage = message;
      if (message == "Chiudi") {
        this.$emit("update:dialog", false);
      }
    });
  },
  computed:{
    dialog: function() {
      return this.value;
    },
  }
};
</script>